import type {
  FileDetailPagedResult,
  ProductSet,
  ReferencePagedResult,
  ProductAccessoryPrice,
  ProductPrice,
  QueryParams,
  ProductSmartFilterPagedResult,
  ProductSetWithImagePagedResult,
} from '~/types/api'
import { APIFilters } from '~/utils/APIFilters'

export function createProductSetsRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'product-sets'
  const DOMAIN_IMAGES = 'public-images'
  const DOMAIN_FILES = 'public-files'
  const DOMAIN_REFERENCES = 'public-references'
  const DOMAIN_PRODUCTS = 'products'
  const DOMAIN_ACCESSORIES = 'product-accessories'
  const DOMAIN_PRICES = 'prices'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      }
    }
    return get<ProductSetWithImagePagedResult>([DOMAIN], { params })
  }

  async function getByNid(nid: number) {
    return get<ProductSet>([DOMAIN, nid])
  }

  // EP takes in slug in form '{line}/{set}' so it can return correct set detail
  async function getBySlug(lineSlug: string, setSlug: string) {
    const params = {} as QueryParams
    const slug = encodeURIComponent(`${lineSlug}/${setSlug}`)
    return get<ProductSet>([DOMAIN, 'slugs', slug], { params })
  }

  async function getAllByLine(productLineNid: number) {
    const filter = {
      [APIFilterOP.EQUALS]: {
        'productLine.nid': productLineNid,
      },
    }
    const params = {
      filter: APIFilters.makeFilter(filter),
      sort: APIFilters.makeSort({ weight: 'ASC' }),
    }
    return get<ProductSetWithImagePagedResult>([DOMAIN], { params })
  }

  async function getAllReferencesPagination(
    page: number,
    itemsPerPage: number,
    setId: number
  ) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<ReferencePagedResult>([DOMAIN, setId, DOMAIN_REFERENCES], {
      params,
    })
  }

  async function getAllImagesPagination(
    page: number,
    itemsPerPage: number,
    setId: number
  ) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<FileDetailPagedResult>([DOMAIN, setId, DOMAIN_IMAGES], {
      params,
    })
  }

  async function getFiles(id: number) {
    return get<FileDetailPagedResult>([DOMAIN, id, DOMAIN_FILES])
  }

  async function getAllProductPrices(
    productSetId: number,
    customerGroupId: number,
    priceCategoryId?: number
  ) {
    const params = {} as QueryParams
    if (priceCategoryId !== undefined) {
      params.priceCategoryId = priceCategoryId
    }

    return get<ProductPrice[]>(
      [
        DOMAIN,
        productSetId.toString(),
        DOMAIN_PRODUCTS,
        DOMAIN_PRICES,
        customerGroupId.toString(),
      ],
      { params }
    ) // TODO: langPath: ['base', DOMAIN, DOMAIN_PRICES]
  }

  async function getAllAccessoryPrices(
    productSetId: number,
    customerGroupId: number,
    priceCategoryId?: number
  ) {
    const params = {} as QueryParams
    if (priceCategoryId !== undefined) {
      params.priceCategoryId = priceCategoryId
    }

    return get<ProductAccessoryPrice[]>(
      [
        DOMAIN,
        productSetId.toString(),
        DOMAIN_ACCESSORIES,
        DOMAIN_PRICES,
        customerGroupId.toString(),
      ],
      { params }
    ) // TODO: langPath: ['base', DOMAIN, DOMAIN_PRICES],
  }

  async function getAllSmartFiltersByNid(productSetNid: number) {
    const params = {
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    } as QueryParams

    return get<ProductSmartFilterPagedResult>(
      [DOMAIN, productSetNid.toString(), 'public-smart-filters'],
      {
        params,
      }
    )
  }

  async function getAllFiltered(params: {
    productCategoryId?: number
    filterTagIds?: number[]
    productProperties?: string[]
    customerGroupNid?: number
    priceCategoryNid?: number
  }) {
    return get<ProductSetWithImagePagedResult>([DOMAIN, 'filter'], {
      params,
    })
  }

  async function getAllFilteredPagination(params: {
    page: number
    itemsPerPage: number
    productCategoryId?: number
    filterTagIds?: number[]
    productProperties?: string[]
    customerGroupNid?: number
    priceCategoryNid?: number
  }) {
    return get<ProductSetWithImagePagedResult>([DOMAIN, 'filter'], {
      params,
    })
  }

  return {
    getAll,
    getByNid,
    getBySlug,

    getAllByLine,

    getAllImagesPagination,
    getFiles,
    getAllReferencesPagination,

    getAllProductPrices,
    getAllAccessoryPrices,

    getAllSmartFiltersByNid,

    getAllFiltered,
    getAllFilteredPagination,
  }
}
